:root {
  --font-family-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --font-family-mono: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  
  /* Dark blue-gray tech theme with improved contrast */
  --color-dark-blue: #121A2B;
  --color-navy: #1E2A45;
  --color-slate: #2D3A57;
  --color-steel-blue: #4A5B89;
  --color-light-blue: #8D9CC4;
  --color-accent-cyan: #38AECC;
  --color-accent-teal: #0090A8;
  --color-accent-purple: #9D4EDD;
  --color-text-light: #F0F4F8;
  --color-text-muted: #B8C4D9;
  --color-white: #FFFFFF;
  --color-black: #0A0F1A;
  --color-card-bg: #1A2438;
  --color-card-hover: #243048;
}

body {
  margin: 0;
  font-family: var(--font-family-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-dark-blue);
  color: var(--color-text-light);
  line-height: 1.6;
  letter-spacing: 0.015em;
  font-weight: 400;
}

code {
  font-family: var(--font-family-mono);
}

/* Tech theme utility classes */
.bg-dark-blue { background-color: var(--color-dark-blue); }
.bg-navy { background-color: var(--color-navy); }
.bg-slate { background-color: var(--color-slate); }
.bg-steel-blue { background-color: var(--color-steel-blue); }
.bg-light-blue { background-color: var(--color-light-blue); }
.bg-accent-cyan { background-color: var(--color-accent-cyan); }
.bg-accent-teal { background-color: var(--color-accent-teal); }
.bg-accent-purple { background-color: var(--color-accent-purple); }
.bg-card { background-color: var(--color-card-bg); }

.text-light { color: var(--color-text-light); }
.text-muted { color: var(--color-text-muted); }
.text-accent-cyan { color: var(--color-accent-cyan); }
.text-accent-teal { color: var(--color-accent-teal); }
.text-accent-purple { color: var(--color-accent-purple); }

/* Improved text legibility */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 0.5em;
  color: var(--color-text-light);
}

p {
  margin-bottom: 1.5em;
}

/* Add subtle tech-themed grid background */
.tech-grid-bg {
  background-image: 
    linear-gradient(rgba(58, 76, 107, 0.07) 1px, transparent 1px),
    linear-gradient(90deg, rgba(58, 76, 107, 0.07) 1px, transparent 1px);
  background-size: 20px 20px;
}

/* Add glow effects for tech theme */
.glow-cyan {
  box-shadow: 0 0 15px rgba(56, 174, 204, 0.5);
}

.glow-purple {
  box-shadow: 0 0 15px rgba(157, 78, 221, 0.5);
}

.glow-teal {
  box-shadow: 0 0 15px rgba(0, 144, 168, 0.5);
}

/* Gradient text for highlights */
.gradient-text {
  background: linear-gradient(90deg, var(--color-accent-cyan), var(--color-accent-purple));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-weight: 700;
}

/* Improved card styling for better readability */
.card-improved {
  border: 1px solid var(--color-slate);
  background-color: var(--color-card-bg);
  transition: all 0.3s ease;
}

.card-improved:hover {
  background-color: var(--color-card-hover);
  transform: translateY(-5px);
}

/* Improved button text */
button, .btn {
  font-weight: 600;
  letter-spacing: 0.02em;
}

/* Add text shadow for better contrast on colored backgrounds */
.text-shadow {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

/* Improved link styling */
a {
  color: var(--color-accent-cyan);
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover {
  color: var(--color-accent-teal);
}
